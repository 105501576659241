import { TextField, InputAdornment } from "@mui/material";
import React, { useState, useRef, useEffect, Fragment } from "react";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import '../../style.css';

export default function Search({
    candidates,
    searchFields,
    arrayType = [],
    id,
    onSearchResultClick,
    renderResultItem,
    label = "Search",
    width = "320px",
    minHeight = 50,
}) {
    const [searchText, setSearchText] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    const handleSearchTextChange = (e) => {
        const text = e.target.value
        setSearchText(text);
        const filteredResults = Screen(candidates, text, searchFields, arrayType);
        setSearchResult(filteredResults);
    };

    const handleSearchResultClick = (e) => {
        onSearchResultClick(e);
        setSearchText("");
        setSearchResult([]);
    };

    return (
        <div style={{ position: "relative" }}>
            <SearchBar
                value={searchText}
                onChange={handleSearchTextChange}
                label={label}
                width={width}
            />
            <ShowSearchResults
                searchResult={searchResult}
                id={id}
                onClick={handleSearchResultClick}
                renderResultItem={renderResultItem}
                minHeight={minHeight}
            />
        </div>
    );
};

const SearchBar = ({ value, onChange, label, width }) => (
    <TextField
        label={label}
        type="search"
        fullWidth
        variant="outlined"
        value={value}
        onChange={onChange}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <img src="/public/icons/search-lg.svg" alt="Search" />
                </InputAdornment>
            ),
            sx: { borderRadius: '8px', width: width }
        }}
    />
);

const Screen = (candidates, searchText, searchFields, arrayType) => {
    if (!searchText) return [];
    const searchTextLower = searchText.toLowerCase()
    return candidates.filter((candidate) =>
        searchFields.some(
            (field) => {
                if (arrayType.includes(field)) {
                    // String matching for each item of array 
                    return candidate[field].some(x => x.toLowerCase().includes(searchTextLower))
                }
                // String matching 
                return candidate[field].toLowerCase().includes(searchTextLower)
            }
        )
    );
};


const ShowSearchResults = ({
    searchResult,
    id,
    onClick,
    renderResultItem,
    minHeight,
}) => {

    const listRef = useRef(null);
    const rowHeights = useRef({});

    // Reset row heights and the list when search results change
    useEffect(() => {
        rowHeights.current = {};
        if (listRef.current) {
            listRef.current.resetAfterIndex(0); // Reset the list
        }
    }, [searchResult]);


    const getRowHeight = (index) => {
        return rowHeights.current[index] || minHeight;
    };

    const setRowHeight = (index, size) => {
        if (rowHeights.current[index] !== size) {
            rowHeights.current[index] = size; // Update the height
            listRef.current?.resetAfterIndex(index); // Reset from the affected row
        }
    };

    const RenderRow = ({ index, style }) => {
        const rowRef = useRef(null);
        const result = searchResult[index];
        useEffect(() => {
            if (rowRef.current) {
                const measuredHeight = rowRef.current.scrollHeight;
                if (measuredHeight) {
                    setRowHeight(index, measuredHeight);
                }
            }
        }, [index]);
        return (
            <div style={style}>
                <div
                    ref={rowRef}
                    className="search-result-container"
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
                    onClick={() => onClick(result[id])}
                >
                    {renderResultItem(result)}
                </div>
            </div>
        );
    };

    if (searchResult.length === 0) return null;


    return (
        <AutoSizer disableHeight>
            {({ width }) => (
                <div className="search-results-container">

                    <List
                        ref={listRef}
                        width={width}
                        height={400}
                        itemCount={searchResult.length}
                        itemSize={getRowHeight}
                    >
                        {RenderRow}
                    </List>
                </div>

            )}

        </AutoSizer>
    );
};




