import SearchResultItem from '../../components/search/SearchResultItem';
import Search from '../../components/search/Search';


export default function SearchBond({ candidates, onSearchResultClick }) {
    const renderResultItem = (result) => {
        return (
            <SearchResultItem
                primary={result.issuer}
                secondary={<>{result.isin}<br />{result.firstAnnouncementDate}</>} />
        );
    };

    return (
        <Search
            candidates={candidates}
            onSearchResultClick={onSearchResultClick}
            searchFields={['isin', 'issuer']}
            id={'isin'}
            renderResultItem={renderResultItem}
            minHeight={50}
        />
    )

}



