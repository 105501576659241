import SearchResultItem from '../../components/search/SearchResultItem';
import Search from '../../components/search/Search';

export default function SearchBank({ candidates, onSearchResultClick }) {
    const renderResultItem = ( result ) => {return <SearchResultItem primary={result.syndicate}/>}
    return (
        <Search
            candidates={candidates}
            onSearchResultClick={onSearchResultClick}
            searchFields={['syndicate']}
            id={'syndicate'}
            renderResultItem={renderResultItem}
        />
    )

}