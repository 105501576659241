
export default function SearchResultItem({ primary, secondary }) {
    return (
        <div>
            <div>
                {primary}
            </div>
            {secondary && ( // Render only if `secondary` is provided
                <div style={{ color: "gray", fontSize: "14px" }}>
                    {secondary}
                </div>
            )}
        </div>
    );
}