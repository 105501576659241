import * as React from "react";
import { DataTable } from '../../components/tables/DataTable';



export default function LeagueTable({ data, onRowClick }) {
    // Virtualization is only possible for data grid for the pro payment version
    // However, one could instead virtualize just a table using virtouso (https://mui.com/material-ui/react-table/#virtualized-table)
    const centered = { align: 'center', headerAlign: 'center' }

    const columns = [
        { field: 'id', headerName: 'Rank', ...centered, description: "Overall Rank within League" },
        { field: 'issuer', headerName: 'Issuer', minWidth: 250, description:"Issuer Name" },
        { field: 'bondType', headerName: 'Bond Type', description:"Bond Type" },   
        { field: 'overallRank', headerName: 'BondRank', ...centered, description: "Overall DebtRay BondRank" },
        { field: 'pricingRank', headerName: 'PricingRank', ...centered, description: "DebtRay PricingRank", display:false },
        { field: 'timingRank', headerName: 'TimingRank', ...centered, description: "DebtRay TimingRank", display:false },
        { field: 'originalSpread', headerName: 'Original Spread', ...centered, description: "Original Spread in Bps", display:true },
        { field: 'firstAnnouncementDate', headerName: 'Announced', description: "First Announcement Date" },
        { field: 'industry', headerName: 'Industry', minWidth: 150, description: "Industry from Bloomberg classification (BICS)" },
        { field: 'isin', headerName: 'Isin', minWidth: 150, display: false },
        { field: 'lifeSpan', headerName: 'Life Span', ...centered, description: "Life Span in Years" },
        { field: 'amountIssued', headerName: 'Volume', ...centered, description: "Issued Volume in Mio" },
        { field: 'principalCurrency', headerName: 'Currency',  display: false },
    ];

    return (
        <DataTable 
        title={'League Table'}
        columns={columns} 
        data={data} 
        onRowClick={onRowClick} 
        pageSize={10}
        initialSorting = {{ field: 'id', sort: 'asc' }} />
    );

}