import * as React from "react";
import { DataTable } from '../../components/tables/DataTable';


export default function CompanyTable({ data, onRowClick }) {
    const centered = { align: 'center', headerAlign: 'center' }
    const columns = [
        { field: 'issuer', headerName: 'Issuer', minWidth: 250, description: "Issuer Name" },
        { field: 'isin', headerName: 'Isin', minWidth: 200, description: "Isin", display: false },
        { field: 'bondType', headerName: 'Bond Type' },
        { field: 'originalSpread', headerName: 'Original Spread', ...centered, description: "Original Spread in Bps", display:false },
        { field: 'firstAnnouncementDate', headerName: 'Announced', description: "First Announcement Date" },
        { field: 'issueDate', headerName: 'Issued', description: "Issue Date", display: false },
        { field: 'maturity', headerName: 'Maturity', description: "Maturity Date", display: false },
        { field: 'lifeSpan', headerName: 'Life Span', type: 'number', ...centered, description: "Life Span in Years" },
        { field: 'amountIssued', headerName: 'Volume', type: 'number', ...centered, description: "Issued Amount in Mio." },
        { field: 'overallRank', headerName: 'BondRank', type: 'number', ...centered, description: "Overall DebtRay BondRank" },
        { field: 'pricingRank', headerName: 'PricingRank', type: 'number', ...centered, description: "DebtRay PricingRank" },
        { field: 'timingRank', headerName: 'TimingRank', type: 'number', ...centered, description: "DebtRay TimingRank" },

    ];

    return (
        <DataTable
            title={'Bond List'}
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            pageSize={30} 
            initialSorting = {{ field: 'firstAnnouncementDate', sort: 'desc' }}/>
    );
}


