import Admin from "./admin/Admin";
import Login from "./users/Login";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useRouteError } from 'react-router-dom';

import React, { useState, useEffect } from "react";
import Company from "./bond_report/company/Company";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Navbar from './components/Navbar';
import InfoPage from './components/InfoPage';
import { SharedIsinProvider } from "./contexts/SharedIsin";
import { Bond } from './bond_report/bond/Bond';
import Bank from './bond_report/bank/Bank';
import ExploreBonds from './bond_report/exploreBonds/ExploreBonds';
import { getCookie, getSettingsFromCookie } from "./helpers/cookies";
import Register from './users/Register';
import Logout from './users/Logout';
import { LeagueFilterProvider } from "./contexts/Filters";
import fetchWithRefresh from "./helpers/api";




export default function App() {
    // Take from cookie if exists
    // Todo: Do we really need to propagate setSettings down to login if we are working with cookies
    const defaultSettings = getCookie('settings') === null ? { isLoggedIn: false } : getSettingsFromCookie()
    const [settings, setSettings] = useState(defaultSettings)

    const PrivateRoute = ({ children }) => {
        return settings.isLoggedIn ? children : <Navigate to='/login' />
    }

    const AdminOnlyRoute = ({ children }) => {
        return settings.isLoggedIn && settings.view === 'admin' ? children : <Navigate to={"/not-allowed"} />
    }

    const [meta, setMeta] = useState(undefined)
    
    // Error handling for expired session id is currently not considered
    // However, this should be rewritten anyway
    const fetchMetaData = async (setMetaData) => {
        try {
            const response = await fetchWithRefresh('/api/bond_ranking/meta');
            const data = await response.json();
            setMetaData(data);
        } catch (error) {
            console.log('Error retrieving Meta Data', error);
        }
    };
    useEffect(() => {
        // check if reload gets new limits console.log("Meta data update")
        fetchMetaData(setMeta);
    }, [settings]);


    return (
        <ThemeProvider theme={theme}>
            <SharedIsinProvider>
                <LeagueFilterProvider limits={meta ? meta.availableLeagueLimits : {}}>
                    <BrowserRouter>
                        <Navbar settings={settings} />
                        <Routes>
                            <Route path="/" element={<Navigate to="/bond/issuer-view" />} />
                            <Route path="/login" element={<Login setSettings={setSettings} />} />
                            <Route path="/logout" element={<Logout />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/admin" element={<AdminOnlyRoute><Admin /></AdminOnlyRoute>} />
                            <Route path="/bond/issuer-view" element={<PrivateRoute><Company defaultCompany={settings.company} /></PrivateRoute>} />
                            <Route path="/bond/bond-explorer" element={<PrivateRoute><ExploreBonds limits={meta ? meta.availableLeagueLimits : {}} /></PrivateRoute>} ></Route>
                            <Route path="/bond/bank-ranking" element={<PrivateRoute><Bank /></PrivateRoute>} />
                            <Route path="/bond/bond-ranking" element={<PrivateRoute><Bond /></PrivateRoute>} />
                            <Route path="/session-expired" element={<InfoPage title={"Your session has expired! Please log in again."} redirectToLogin={true} />} />
                            <Route path="/not-allowed" element={<InfoPage title={"Access not allowed!"} />} />
                            <Route path="/not-found" element={<InfoPage title={"Page not found"} />} />
                            <Route path="*" element={<Navigate to="/not-found" />} />
                        </Routes>
                    </BrowserRouter>
                </LeagueFilterProvider>
            </SharedIsinProvider>
        </ThemeProvider>
    );
}
