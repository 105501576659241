import TimeSeriesBarPlot from "../../components/plots/TimeSeriesBarPlot";
import * as React from "react";
import GraphBox from "../../components/GraphBox";
import { Box } from '@mui/material';
import BoxTitle from "../../components/headers/BoxTitle";
import CircleWithRank from "../../components/CircleWithRank";
import { roundWithNull } from '../../helpers/handleNull';
import { SectionTitle } from '../../components/headers/SectionTitle';
import { InfoTable } from '../../components/tables/InfoTable';
import { bondInfo } from "./InfoTexts";
import Info from '../../components/Info';



export function TimingSummary({ data, flex }) {
    const rows = [
        {
            key: 'Announcment Date',
            value: data.firstAnnouncementDate
        },
        {
            key: 'Realized Yield over Average',
            value: `${roundWithNull(data.timingAverageDeltaYield)} bps`
        },
        {
            key: `Interest Expense ${data.timingAverageGain >= 0 ? 'Gain' : 'Loss'} over Average`,
            value: `${roundWithNull(data.timingAverageGain, 1)} m ${data.principalCurrency}`
        },
    ]
    return (
        <GraphBox paddingBottom='16px' flex={flex}>
            <BoxTitle
                title={'Timing Statistics'}
                icon={<img src="/public/icons/timer-line.svg" alt="Timer" />}
                paddingBottom="16px" />
            <InfoTable rows={rows} />
        </GraphBox>
    );
}


export function TimeSeries(props) {
    const { x,
        xLabel = 'Alternative Issuance Day  (+/- 6 Weeks around Date of Issuance)',
        xUnit = 'Days',
        y,
        yMean,
        yLabel,
        yUnit, precision = 0,
        title,
        info,
        flex,
        colorize,
        flipSign = false,
        height } = props

    return (
        <GraphBox flex={flex} height={height}>
            <Box paddingBottom={'24px'}>
                <Box display="flex" alignItems="flex-start">
                    <BoxTitle title={title} />
                    {info && <Info title={title} info={info} />}
                </Box>
            </Box>
            <Box flex={'1'}>
                <TimeSeriesBarPlot
                    xUnit={xUnit}
                    yUnit={yUnit}
                    /* Change sign as we work with loss (interest expense) instead of gain */
                    x={{ values: x, label: xLabel }}
                    y={{ values: flipSign ? y.map(value => -value) : y, label: yLabel }}
                    yMean={yMean}
                    precision={precision}
                    colorize={colorize} />
            </Box>
        </GraphBox>
    );
}


export default function TimingOverview(props) {
    const { data } = props
    const rankingExists = data.data.timingRank != null
    return (
        <>
            <SectionTitle title={'Timing Analysis'} />

            <div className='row-container' style={{ marginBottom: '24px' }}>
                <TimingSummary
                    data={data.data}
                    flex={'1 1 0'} />
                <div style={{ flex: '.5 1 0' }}>
                    <CircleWithRank rank={data.data.timingRank} label={'TimingRank'} diameter={124} />
                </div>
                <div style={{ flex: '1.5 1 0' }}></div>
            </div>
            
            {rankingExists ?
                <>
                    <div className='row-container' style={{ marginBottom: '24px' }}>
                        <TimeSeries
                            x={data.data.timingDeltaDays}
                            y={data.data.timingGain}
                            flipSign={true}
                            yMean={data.data.timingAverageGain}
                            yLabel='Delta Expense'
                            yUnit='m'
                            precision={1}
                            title='Alternative Interest Expenses (Delta in millions)'
                            info={bondInfo.alternativeInterestExpenses}
                            colorize={true}
                            height={'400px'}
                        />
                    </div>

                    <div className='row-container' style={{ marginBottom: '72px' }}>
                        <TimeSeries
                            x={data.data.timingDeltaDays}
                            y={data.data.timingDeltaYield}
                            yMean={-data.data.timingAverageDeltaYield}
                            yLabel='Delta Yield'
                            yUnit='bps'
                            precision={0}
                            title='Alternative Yield (Delta in bps)'
                            flex={'1 1 0'}
                            colorize={true}
                            height={'400px'} />
                    </div>
                </> :
                <Box display='flex' alignContent='center' justifyContent='center'>
                    <p>No further timing analysis available</p>
                </Box>
            }
        </>)
}
