import * as React from "react";
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const SummaryItem = ({summary}) => {
    const theme = useTheme()
    return (<Box
        backgroundColor={theme.palette.primary[50]}
        border={`1px solid ${theme.palette.primary[700]}`}
        padding='3px'
        paddingLeft='8px'
        paddingRight='8px'
        borderRadius={8}>
        <Typography
            color={theme.palette.primary[700]}>
            {summary}
        </Typography>
    </Box>
    )
}

export default function FilterSummary({ data }) {
    if (data) {
        const summary = data.leagueSummary
        const minDate = summary.minDate.split('-')
        const maxDate = summary.maxDate.split('-')
        const minDateString = `${minDate[1]}/${minDate[0]}`
        const maxDateString = `${maxDate[1]}/${maxDate[0]}`

        return (
            <>
                {summary.bondTypes.map((bondType, index) => (
                    <SummaryItem key={index} summary={`${bondType}s`}/>
                ))}
                <SummaryItem summary={`${minDateString} - ${maxDateString}`}/>
                <SummaryItem summary={`${summary.nBonds} Issues`}/>
            </>
        )
    }
}